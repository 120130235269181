/**
 * determine if the path given is excluded on i18n
 * @param {string} originalPath
 * @returns {boolean}
 */
export const isPathExcluded = (originalPath) => {
  const excludedPaths = [
    "/blog",
    "/research-participation-agreement",
    "/terms",
    "/data-privacy-policy",
    "/payment-policy",
    "/the-mena-ccommerce-report-2024",
  ];

  const excludePaths = excludedPaths.filter((path) =>
    originalPath.includes(path)
  );

  return Boolean(excludePaths.length);
};
