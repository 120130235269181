import { Link as RemixLink } from "@remix-run/react";
import useLinkHref from "app/utils/href";

export default function Link({ children, to, reloadDocument = true, ...rest }) {
  const href = useLinkHref(to);

  return (
    <RemixLink to={href} {...rest} reloadDocument={reloadDocument}>
      {children}
    </RemixLink>
  );
}
