import { useI18nLanguage } from "app/hooks/i18n";
import config from "app/config/i18n";
import { isPathExcluded } from "app/utils/path";

export default function useLinkHref(to) {
  const { locale } = useI18nLanguage();

  let href = to;

  if (
    config.supportedLngs.includes(locale) &&
    locale !== config.fallbackLng &&
    !isPathExcluded(to)
  ) {
    href = `/${locale}${to}`;
  }

  return href;
}
